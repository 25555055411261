import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import Selectors from "../Selectors";
import { withPage } from "../PageContainer";
import * as Catalog from "../Contexts/CatalogContext";
import * as Cart from "../Contexts/CartContext";
import * as Ant from "antd";
import ProductImageCarousel from "../Components/ProductImageCarousel";
import ProductImageLightBox from "../Components/ProductImageLightBox";
import ProductBottomTabs from "../Components/ProductBottomTabs";
import * as AppEvents from "../AppEvents";
import ActionCreator from "../ActionCreator";
import Constant from "../constants";
import Image from "../Components/ImageBackground";
import * as Theme from "../Theme";
import TeacherData from "../Components/TeacherData";
import QuestionModal from "../Modals/QuestionModal";

class ProductDetailPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      loadingBuy: false,
      loadingRedirectCart: false,
      product: null,
      favorite_id: null,
      mySubscriptions: [],
      boughtMode: false,
      openQuestionModal: false,
    };
  }

  componentDidMount() {
    this._getProduct();
  }

  async componentDidUpdate(prevProps, prevState) {
    let mySubscriptions = [];

    if (
      prevState.product !== this.state.product ||
      (prevProps.profile !== this.props.profile && this.props.profile)
    ) {
      try {
        mySubscriptions = await this._getMySubscription();
        if (mySubscriptions && mySubscriptions.length > 0) {
          this._isBoughtMode(mySubscriptions);
        }
      } catch (ex) {
        console.warn(ex);
      }
    }
  }

  render() {
    const {
      loading,
      loadingBuy,
      loadingRedirectCart,
      product,
      boughtMode,
      openQuestionModal,
    } = this.state;
    return (
      <Wrapper>
        {!this.props.queryParams.id ? (
          <div className="center-content">
            <h1>無此商品</h1>
          </div>
        ) : !product || loading ? (
          <div className="center-content">
            <Ant.Spin spinning={loading} />
          </div>
        ) : (
          <div className="center-content">
            <Ant.Row gutter={[20, 20]}>
              <Ant.Col xs={24} sm={24} md={24} lg={13}>
                {loading && <Ant.Spin />}

                {/* <ProductImageCarousel imagesArray={getProductImages(product)} />
                <div style={{ height: 10 }} />
                <ProductImageLightBox imagesArray={getProductImages(product)} />
                <div style={{ height: 10 }} /> */}
                <ImageContainer>
                  <Image src={product.image} css="width: 100%;" />
                </ImageContainer>
              </Ant.Col>

              <Ant.Col
                xs={24}
                sm={24}
                md={24}
                lg={11}
                style={{ position: "relative" }}
              >
                <h2 style={{ color: Theme.colors.main }}>{product.name}</h2>
                <div>
                  <Row css="margin-bottom: 40px;">
                    <ContentText>{product.description}</ContentText>
                  </Row>
                  <Row>
                    <TeacherData teacher={product.teacher} />
                  </Row>
                  <Row css="margin-bottom: 30px;">
                    <div>
                      <ContentText>
                        課程總時數：{product.total_time} 分鐘
                      </ContentText>
                      <ContentText>
                        課程總節數：{product.video_count} 節
                      </ContentText>
                    </div>
                    <div style={{ flex: 1 }} />
                    <Price style={{ flexShrink: 0 }}>NT ${product.price}</Price>
                  </Row>

                  <div style={{ display: "flex" }}>
                    {boughtMode ? (
                      <Ant.Button
                        type="primary"
                        loading={loadingRedirectCart}
                        disabled={loadingBuy || loadingRedirectCart}
                        onClick={async () => {
                          this.props.navActions.push(
                            `/video-course?id=${this.props.queryParams.id}`
                          );
                        }}
                        size="large"
                        style={{
                          marginRight: 10,
                          fontSize: 14,
                          borderRadius: 10,
                        }}
                      >
                        上課囉！{" "}
                      </Ant.Button>
                    ) : (
                      <div style={{ display: "flex" }}>
                        <Ant.Button
                          loading={loadingRedirectCart}
                          disabled={loadingBuy || loadingRedirectCart}
                          onClick={async () => {
                            this.props.profile &&
                              this.setState({ loadingRedirectCart: true });
                            await this._addToCart();
                            this.props.profile &&
                              this.props.navActions.push("/cart/");
                          }}
                          size="large"
                          style={{
                            marginRight: 10,
                            fontSize: 14,
                            borderRadius: 10,
                          }}
                        >
                          立即購買{" "}
                        </Ant.Button>
                        <Ant.Button
                          type="primary"
                          loading={loadingBuy}
                          disabled={loadingBuy || loadingRedirectCart}
                          onClick={async () => {
                            this.setState({ loadingBuy: true });
                            await this._addToCart();
                          }}
                          size="large"
                          style={{ fontSize: 14, borderRadius: 10 }}
                        >
                          加入購物車{" "}
                        </Ant.Button>
                      </div>
                    )}
                    <div style={{ flex: 1 }} />
                    <Ant.Button
                      disabled={loadingBuy || loadingRedirectCart}
                      onClick={() => {
                        this.setState({ openQuestionModal: true });
                      }}
                      size="large"
                      style={{ fontSize: 14, borderRadius: 10 }}
                    >
                      我要發問{" "}
                    </Ant.Button>
                  </div>
                </div>
              </Ant.Col>
            </Ant.Row>

            <div
              className="center-content"
              style={{ paddingTop: 20, paddingBottom: 20 }}
            >
              <ProductBottomTabs product={product} />
            </div>
            {openQuestionModal && (
              <QuestionModal
                productId={product.id}
                onClose={() => this.setState({ openQuestionModal: false })}
              />
            )}
          </div>
        )}
      </Wrapper>
    );
  }

  _getProduct = async id => {
    this.setState({ loading: true });
    try {
      const product_id = this.props.queryParams.id;
      if (product_id) {
        const product = await Catalog.Actions.fetchProduct(product_id);
        this.setState({
          product,
          favorite_id: product.favorite,
        });
      }
    } catch (ex) {
      console.warn(ex);
    }
    this.setState({ loading: false });
  };

  _isBoughtMode = mySubscriptions => {
    let { product } = this.state;
    let isBoughtMode =
      mySubscriptions.map(s => s.product.id).indexOf(product.id) >= 0;
    this.setState({ boughtMode: isBoughtMode });
  };

  _getMySubscription = async () => {
    try {
      const mySubscriptions = await Catalog.Actions.fetchMySubscriptions();
      return mySubscriptions;
    } catch (ex) {
      console.warn(ex);
    }
    return [];
  };

  _addToCart = async () => {
    const { profile } = this.props;

    if (!profile) {
      AppEvents.EvtShowLoginModal.emit(true);
      this.setState({ loadingBuy: false, loadingRedirectCart: false });
      return;
    }

    try {
      const { product } = this.state;

      await Cart.Actions.addItem(product.id);
    } catch (ex) {
      console.warn("addToCart", ex);

      if (ex instanceof Cart.ErrCartNotAvailable) {
        Ant.message.error("取得購物車資訊錯誤");
      } else {
        Ant.message.error("加入購物車發生錯誤。");
      }
    }
    this.setState({ loadingBuy: false, loadingRedirectCart: false });
  };
}

const Wrapper = styled.div`
  padding: 20px;
  background-color: #f7f7f7;

  .center-content {
    max-width: 1024px;
    margin: 0px auto;

    & label {
      width: 100px;
      font-weight: bold;
      color: #999;
    }

    & .description {
      margin: 30px 100px;
      width: 100% - 200px;

      @media screen and (max-width: 600px) {
        margin: 0px;
      }
    }
  }
`;

const Tag = styled.div`
  padding: 2px 8px;
  display: inline-block;
  color: white;
  border-radius: 8px;
  background-color: red;
  font-size: 14px;
  ${props => props.css || ""}
`;

const ContentText = styled.p`
  margin: 0px;
  color: ${Theme.colors.text};
  font-weight: 300;
  white-space: pre-line;
`;

const ImageContainer = styled.div`
  border: 1px solid lightgray;
  width: 100%;
  height: 340px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin-bottom: 10px;
  color: #999;

  & > label {
    margin-right: 10px;
  }
  ${props => props.css}
`;

const Price = styled.div`
  text-align: right;
  font-weight: 300;
  letter-spacing: 1px;
  color: ${Theme.colors.main};
  font-size: 26px;
`;

export default withPage(
  Catalog.withConsumer(
    connect(
      (state, ownProps) => ({
        profile: Selectors.getLoginUser(state),
        queryParams: Selectors.getQueryParams(ownProps),
      }),
      ActionCreator
    )(ProductDetailPage)
  )
);
