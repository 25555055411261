import React from "react";
import styled from "styled-components";
import Image from "./Image";
import CmsView from "./CmsView";
import Constants from "../constants";
import ReactPlayer from "react-player";

const TABS = {
  INTRO: 0,
  DATA: 1,
  BULLETIN: 2,
};
class ProductBottomTabs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabIndex: TABS.INTRO,
    };
  }

  render() {
    const { tabIndex } = this.state;
    let { product } = this.props;

    // let introImages =
    //   product &&
    //   (product.image
    //     ? [...product.images.filter(image => image.name == "intro")]
    //     : product.images
    //   ).map(image => ({ src: image.image, link: image.link }));

    // let specImages =
    //   product &&
    //   (product.image
    //     ? [...product.images.filter(image => image.name == "spec")]
    //     : product.images
    //   ).map(image => ({ src: image.image, link: image.link }));

    // let noticeImages =
    //   product &&
    //   (product.image
    //     ? [...product.images.filter(image => image.name == "notice")]
    //     : product.images
    //   ).map(image => ({ src: image.image, link: image.link }));

    return (
      <Wrapper>
        <TabBar>
          <TabItem
            selected={tabIndex === TABS.INTRO}
            onClick={() => this.setState({ tabIndex: 0 })}
          >
            課程介紹
          </TabItem>
          <TabItem
            selected={tabIndex === TABS.DATA}
            onClick={() => this.setState({ tabIndex: 1 })}
          >
            課程列表
          </TabItem>
          <TabItem
            selected={tabIndex === TABS.BULLETIN}
            onClick={() => this.setState({ tabIndex: 2 })}
          >
            課程公告
          </TabItem>
        </TabBar>

        <TabContent>
          {tabIndex === TABS.INTRO && (
            <div className="image-container">
              <CmsView cmsContent={product.intro} />
            </div>
          )}

          {tabIndex === TABS.DATA && (
            <div className="image-container">
              <CmsView cmsContent={product.data} />
            </div>
          )}

          {tabIndex === TABS.BULLETIN && (
            <div className="image-container">
              <CmsView cmsContent={product.bulletin} />
            </div>
          )}
        </TabContent>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  /* border: 1px solid #ddd; */
`;

const TabBar = styled.div`
  display: flex;
`;

const TabItem = styled.div`
  border-radius: 10px 10px 0px 0px;
  padding: 10px;
  flex: 1;
  text-align: center;
  color: gray;
  background-color: ${props => (props.selected ? "white" : "#ccc")};
`;

const TabContent = styled.div`
  background-color: white;
  border-radius: 0px 0px 10px 10px;
  min-height: 200px;
  padding: 20px;

  .video-container {
    width: 560px;
    height: 315px;
    margin: 0 auto;

    @media screen and (max-width: 600px) {
      width: 100%;
    }
  }
  .image-container {
    margin: 0px 100px;
    width: 100% - 200px;

    @media screen and (max-width: 600px) {
      margin: 0px;
    }
  }
`;

export default ProductBottomTabs;
