import React, { Component } from "react";
import styled from "styled-components";
import Carousel from "nuka-carousel";
import ReactPlayer from "react-player";
import * as Icon from "./Icon";
import Image from "./ImageBackground";

class ProductImageCarousel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mounted: false,
      slideIndex: 0,
    };
  }

  componentDidMount() {
    this.setState({ mounted: true });
  }

  render() {
    let { video, imagesArray } = this.props;
    let { slideIndex, mounted } = this.state;

    if (!mounted || imagesArray.length === 0) {
      return (
        <div style={{ width: "100%", height: 300, backgroundColor: "grey" }} />
      );
    }

    return (
      <Carousel
        autoplay={!video ? true : false}
        width={"100%"}
        initialSlideHeight={300}
        renderBottomCenterControls={() => false}
        renderCenterLeftControls={this._renderLeftIcon}
        renderCenterRightControls={this._renderRightIcon}
        slideIndex={slideIndex}
        afterSlide={slideIndex => this.setState({ slideIndex })}
      >
        {video && (
          <ImageContainer>
            <ReactPlayer
              url={video.video_link}
              width={"100%"}
              height={"100%"}
              playing={slideIndex === 0 ? true : false}
            />
          </ImageContainer>
        )}
        {imagesArray
          // .filter(image => image.name == "top")
          .map((d, i) => (
            <ImageContainer key={"carousel-image" + i}>
              <Image src={d.image} css="width: 100%;" />
            </ImageContainer>
          ))}
      </Carousel>
    );
  }

  _renderLeftIcon = ({ previousSlide }) => (
    <IconContainer onClick={previousSlide}>
      <Icon.KeyboardArrowLeft
        color="#4e4e4e"
        style={{ width: 35, height: 35 }}
      />
    </IconContainer>
  );

  _renderRightIcon = ({ nextSlide }) => (
    <IconContainer onClick={nextSlide}>
      <Icon.KeyboardArrowRight
        color="#4e4e4e"
        style={{ width: 35, height: 35 }}
      />
    </IconContainer>
  );
}

const IconContainer = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
  border: 0px;
  background-color: rgba(255, 255, 255, 0.4);
  width: 40px;
  height: 40px;

  :focus {
    outline: none;
  }
`;

const ImageContainer = styled.div`
  border: 1px solid lightgray;
  width: 100%;
  height: 340px;
`;

export default ProductImageCarousel;
