import React from "react";
import * as Ant from "antd";
import styled from "styled-components";
import { connect } from "react-redux";
import ActionCreator from "../ActionCreator";
import * as AppEvents from "../AppEvents";
import * as Theme from "../Theme";
import * as Catalog from "../Contexts/CatalogContext";
import UploadFileBtn from "../Components/UploadFileButton";

class QuestionModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        name: "",
        email: "",
        phone: "",
        title: "",
        body: "",
        image: "",
      },
    };
  }
  render() {
    let { form } = this.state;
    let { onClose } = this.props;
    return (
      <Ant.Modal
        visible={true}
        onCancel={onClose}
        width="400px"
        onOk={this._createAQuestion}
        onCancel={onClose}
        okText="傳送"
        cancelText="取消"
      >
        <Wrapper>
          <Title>{`請留下基本資料，
我們將由專人回信至email
以回答您的問題！`}</Title>

          <Line />
          <InputContainer>
            <p>您的名稱 *</p>
            <TextInput
              value={form.name}
              onChange={e =>
                this.setState({ form: { ...form, name: e.target.value } })
              }
            />
          </InputContainer>
          <InputContainer>
            <p>E-mail *</p>
            <TextInput
              value={form.email}
              onChange={e =>
                this.setState({ form: { ...form, email: e.target.value } })
              }
            />
          </InputContainer>
          <InputContainer>
            <p>手機號碼</p>
            <TextInput
              placeholder="(選填)"
              value={form.phone}
              onChange={e =>
                this.setState({ form: { ...form, phone: e.target.value } })
              }
            />
          </InputContainer>
          <InputContainer>
            <p>問題標題 *</p>
            <TextInput.TextArea
              value={form.title}
              onChange={e =>
                this.setState({ form: { ...form, title: e.target.value } })
              }
            />
          </InputContainer>
          <InputContainer>
            <p>問題內文 *</p>
            <TextInput.TextArea
              value={form.body}
              onChange={e =>
                this.setState({ form: { ...form, body: e.target.value } })
              }
            />
          </InputContainer>
          <InputContainer>
            <p>圖片上傳</p>
            <input
              // key={inputKey}
              onClick={e => e.stopPropagation()}
              type="file"
              onChange={e =>
                this.setState({ form: { ...form, image: e.target.files[0] } })
              }
            />
          </InputContainer>
        </Wrapper>
      </Ant.Modal>
    );
  }

  _createAQuestion = async () => {
    let { form } = this.state;
    let { productId, videoId, onClose } = this.props;
    let request = {};

    if (productId) {
      request = {
        ...form,
        product: productId,
      };
    } else if (videoId) {
      request = {
        ...form,
        video: videoId,
      };
    } else {
      request = { ...form };
    }
    try {
      let resp = await Catalog.Actions.createAQuestion(request);
      Ant.message.success("建立成功！");
      onClose();
    } catch (err) {
      console.warn(err);
      Ant.message.error("建立失敗，請確定網路連線並重試");
    }
  };
}

const Wrapper = styled.div``;

const Title = styled.div`
  color: ${Theme.colors.main};
  font-weight: 450;
  line-height: 22px;
  letter-spacing: 0.5px;
  white-space: break-spaces;
`;

const Line = styled.div`
  height: 1px;
  background-color: #d9d9d9;
  width: 100%;
  margin: 15px 0px;
`;

const InputContainer = styled.div`
  padding-bottom: 15px;
  & > p {
    font-weight: 300;
    color: #444444;
    margin: 0px 0px 5px 0px;
  }
`;

const TextInput = styled(Ant.Input)`
  flex-basis: 220px;

  :hover {
    border-color: ${Theme.colors.main}4;
  }

  :focus {
    border-color: ${Theme.colors.main};
    outline-color: ${Theme.colors.main}4;
    outline: 0;
    -webkit-box-shadow: 0 0 0 2px rgba(40, 166, 137, 0.2);
    box-shadow: 0 0 0 2px rgba(40, 166, 137, 0.2);
  }

  .ant-input::selection {
    border-color: green;
  }

  ${props =>
    props.css} /* ::after {
    border-color: green;
    background: green;
} */
`;

export default QuestionModal;
